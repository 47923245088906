import { Auth } from 'Shared/auth';
import { MediaTarget } from './target';
import { Modal } from 'Shared/modal';
import { MediaItem } from './item';
import MediaItemMenu from './item_menu';
import MediaLibrary from './library';
import '../../../3P/javascript-state-machine/state-machine';

onDomReady(function () {
   MediaManager.initialize();
});

/**
 * See docs in the README for this directory
 */

export const MediaManager = (window.MediaManager = (function () {
   let mediaItemMenu;
   let attachedItems = {};

   MediaItem.addEvent('toggleMenu', function (mediaItem, menuAnchor) {
      mediaItemMenu.toggleForMediaItem(mediaItem, menuAnchor);
   });

   function setupImageMarkersDialog() {
      Modal.addEvents({
         onImageMarkersLoad: function (contentBox, options, responseData) {
            ImageMarkers.initialize(contentBox, options, responseData);
         },
         onImageMarkersUnload: function () {
            ImageMarkers.unload();
         },
      });
   }

   let publicInterface = {
      // holds the mediaTarget that is being altered
      mediaTarget: null,

      initialize: function () {
         MediaTarget.registerAllTargets();

         setupMediaItemMenu();
      },

      // When a mediaItem is selected from Media Manager, it is appended to
      // `attachedItems`. When it is `detach`ed, it goes back into the list of
      // media.
      detach: function (mediaItem) {
         Auth.required({
            onAuthorize: function () {
               delete attachedItems[mediaItem.getGlobalID()];
               mediaItem.deleted();
            },
         });
      },

      /**
       * Called when we enter the choosing state
       */
      onenterchoosing: function (event, from, to, mediaTarget) {
         // If the user cancelled the crop dialog, Modal.js will take
         // care of showing the Library. Effectively repeating this comand
         if (from != 'hidden') {
            return;
         }

         MediaLibrary.showForTarget(mediaTarget, complete, attachedItems);

         function complete(mediaItem) {
            if (!mediaItem) {
               return MediaManager.cancel();
            }

            let filter_state = mediaItem.data.filter_state();
            // see MediaFilters.php for what these values mean
            switch (filter_state) {
               case 'crop':
                  MediaManager.crop(mediaItem, mediaTarget);
                  break;
               case 'reduced_quality':
                  MediaLibrary.appendPromptMessage(
                     _js(
                        'The current image size (%1x%2) is smaller than 800x600, it will have quality reduced. Do you still want to use it?',
                        mediaItem.data.width(),
                        mediaItem.data.height()
                     ),
                     'notice',
                     function (confirmed) {
                        if (confirmed) {
                           MediaManager.select(mediaItem, mediaTarget);
                        } else {
                           return;
                        }
                     }
                  );
                  break;
               // If true, it meets all the requirements; Attach it.
               case true:
               // If WRONG_TYPE, let the target reject it with a custom error
               // message
               // falls through
               case false:
                  MediaManager.select(mediaItem, mediaTarget);
                  break;
               default:
                  // If the image is still uploading, the filter_state will be
                  // undefined.
                  if (filter_state) {
                     MediaLibrary.appendAlertMessage(filter_state.message);
                  }
                  break;
            }
         }
      },

      oncrop: function (event, from, to, mediaItem, mediaTarget) {
         ImageCrop.showForMediaItem(mediaItem, mediaTarget, function complete(mediaItem) {
            if (mediaItem) {
               MediaManager.select(mediaItem, mediaTarget);
               // Pop the media library off the modal stack too
               // so we go straight back to the page.
               Modal.pop();
            } else {
               MediaManager.cancelCrop();
            }
         });
      },

      /**
       * Called before transitioning via the 'select' method. Gives the target
       * an opportunity to veto the selection or perform it's own actions.
       *
       * If ASYNC state transitions were enabled in onbeforeselect() I would
       * have done that... but they only work for onleave* functions.
       */
      onleavechoosing: validateSelection,
      onleavecropping: validateSelection,

      onenterhidden: function () {
         Modal.pop();
      },
   };

   /**
    * If we are performing the 'select' event,
    * Attach the item to the target and give it the option to veto the
    * attachment.
    */
   function validateSelection(event, from, to, mediaItem, mediaTarget) {
      if (event !== 'select') {
         return true;
      }
      let mediaManager = this;
      mediaTarget.attachItem(mediaItem, function (oldMediaItem, success, msg) {
         if (!success) {
            if (msg) {
               MediaLibrary.appendAlertMessage(msg);
            }
            return mediaManager.transition.cancel();
         }

         if (oldMediaItem) {
            delete attachedItems[oldMediaItem.getGlobalID()];
         }

         /**
          * If the media item requires cropping, it will have a different Id
          * when it's done. So, we'll wait for that.
          */
         mediaItem.whenReady(function () {
            attachedItems[mediaItem.getGlobalID()] = true;
         });
         mediaManager.transition();
      });

      // This transition will be asyncronous cause we need to wait on the
      // above callback to verify success.
      return StateMachine.ASYNC;
   }

   /**
    * Setup event handlers for the MediaItemMenu
    *
    * For most of these actions, we don't really care about completion / cancel
    * events cause dialogs do Modal.pop() and leave us right where we started.
    */
   function setupMediaItemMenu() {
      let self = this;
      mediaItemMenu = new MediaItemMenu();
      let menuItems = mediaItemMenu.menuItems;

      setupImageMarkersDialog();

      menuItems.addEvents({
         markers: function (mediaItem) {
            Auth.required({
               onAuthorize: function () {
                  if (App.imageMarkupV2Enabled) {
                     const event = new CustomEvent('openMarkersEditor', {
                        detail: {
                           imageid: mediaItem.getID(),
                        },
                     });
                     document.dispatchEvent(event);
                     return;
                  }

                  let clientOptions = { mediaImage: mediaItem };
                  Modal.open({
                     type: 'module',
                     name: 'ImageMarkers',
                     boxClass: 'mediaLibraryModalBox editPhoto',
                     clientOptions: clientOptions,
                     serverOptions: {
                        imageid: mediaItem.getID(),
                     },
                  });
               },
            });
         },
         crop: function (mediaItem) {
            Auth.required({
               onAuthorize: function () {
                  if (App.imageMarkupV2Enabled) {
                     const event = new CustomEvent('openImageEditor', {
                        detail: {
                           imageid: mediaItem.getID(),
                        },
                     });
                     document.dispatchEvent(event);
                     return;
                  }

                  let itemContext = mediaItem.getContext();
                  let mediaTarget = null;

                  // If the mediaItem is in the media manager, we still need to
                  // have the crop dialog aware of the mediaTarget the library is
                  // open for so it can get the filter state (pass, too small, needs
                  // crop, ...) after cropping and enforce the appropriate aspect
                  // ratio.
                  if (itemContext == MediaLibrary) {
                     mediaTarget = MediaLibrary.mediaTarget;
                     // If the mediaItem is attached to a target, make sure to use that
                     // as the context for the filter.
                  } else if (instanceOf(itemContext, MediaTarget)) {
                     mediaTarget = itemContext;
                  }

                  ImageCrop.showForMediaItem(mediaItem, mediaTarget);
               },
            });
         },
         deleted: function (mediaItem) {
            publicInterface.detach(mediaItem);
         },
         fullsize: function (mediaItem) {
            // Get image source URL, remove '.thumbnail' suffix, and open
            // it in a new window.
            let imageURL = mediaItem.data.original();
            window.open(imageURL);
         },
         copy: function (mediaItem) {
            Auth.required({
               onAuthorize: mediaItem.copyToMediaLibrary.bind(mediaItem),
            });
         },
      });
   }

   return publicInterface;
})());

/**
 * Taken from: https://github.com/jakesgordon/javascript-state-machine
 */
StateMachine.create({
   target: MediaManager,
   initial: 'hidden',

   events: [
      // cancelling is allowed from anywhere
      { name: 'cancel', from: '*', to: 'hidden' },
      { name: 'cancelCrop', from: 'cropping', to: 'choosing' },

      // Triggered by clicking a mediaTarget
      { name: 'browse', from: 'hidden', to: 'choosing' },
      { name: 'crop', from: 'choosing', to: 'cropping' },
      { name: 'select', from: ['choosing', 'cropping'], to: 'hidden' },
   ],
});
